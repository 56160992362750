import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // 顶部搜索条件
    searchKey: {
      kw: "", //商品搜索框值
    },
    num: 0, //购物车红点
    list: [],
    total: 0,
    tabIndex: -1,
    pageName: [],
  },
  getters: {
    searchKey: (state) => state.searchKey,
    num: (state) => state.num,
    list: (state) => state.list,
    total: (state) => state.total,
    tabIndex: (state) => state.tabIndex,
    pageName: (state) => state.pageName,
  },
  mutations: {
    setSearchKey(state, data) {
      state.searchKey = data;
    },
    SAVE_BADGE(state, data) {
      state.num = data;
    },
    SAVE_CART(state, data) {
      state.list = data.data;
      state.total = data.total_price;
    },
    CHANGE_TAB(state, data) {
      state.tabIndex = data;
    },
    CHANGE_PAGENAME(state, data) {
      state.pageName = data;
    },
  },
  actions: {
    getBadge({ commit }) {
      api("cart.getBadge").then((res) => {
        commit("SAVE_BADGE", res.data.num);
      });
      api("cart.getCartList").then((res) => {
        commit("SAVE_CART", res);
      });
    },
  },
});

export default store;
