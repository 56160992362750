export default {
  // 购物车 - 红点
  getBadge: {
    method: "post",
    url: "/shoppingcart/badge/read",
  },
  // 购物车 - 列表
  getCartList: {
    method: "post",
    url: "/shoppingcart/goods/index",
  },
  // 购物车 - 修改数量
  updateNum: {
    method: "post",
    url: "/shoppingcart/goods/update",
  },
  // 购物车 - 删除
  delCart: {
    method: "post",
    url: "/shoppingcart/batchdelete/save",
  },
  // 购物车 - 选中状态
  cartChoose: {
    method: "post",
    url: "/shoppingcart/goods/chose",
  },
  // 购物车 - 提交
  submitCart: {
    method: "post",
    url: "/order/preorder/save",
  },
  // 预下单-读取
  getPreorder: {
    method: "post",
    url: "/order/preorder/read",
  },
  // 预下单-配送方式
  getDeliverymethod: {
    method: "post",
    url: "/order/deliverymethod/index",
  },
  // 预下单-运费读取
  getFreight: {
    method: "post",
    url: "/order/freight/read",
  },
  // 预下单-收货地址
  getAddress: {
    method: "post",
    url: "/address/address/index",
  },
  // 预下单-优惠券
  getCoupon: {
    method: "post",
    url: "/order/coupon/index",
  },
  //预下单-发票类型
  getInvoice: {
    method: "post",
    url: "/order/invoice/index",
  },
  //预下单-是否随货资质
  getQualification: {
    method: "post",
    url: "/order/qualification/index",
  },
  //预下单-支付方式
  getPay: {
    method: "post",
    url: "/order/pay/index",
  },
  //预下单-确认订单
  submitOrder: {
    method: "post",
    url: "/order/order/savepc",
  },
  //购物车属性
  getAttr: {
    method: "post",
    url: "/shoppingcart/goods/attr",
  },
  //微信支付
  weixinpay:{
    method: "post",
    url:"/order/weixin/pay",
  },
  //微信支付
  getOrderState:{
    method: "post",
    url:"/order/pay/status",
  },

};
